<app-filter-notifications (filteredList)="listFilter($event)"></app-filter-notifications>

<nz-tabset nzCentered>
  <nz-tab nzTitle="Todas">
    <div #scrollContainer class="scrollable-container">
    <nz-spin [nzSpinning]="isLoading" id="spinner"></nz-spin>
    <app-all-notifications [listItems]="listContent" (loadMore)="getAllNotifications()" [ngClass]="{ 'loading': isLoading }"></app-all-notifications>
    </div>
  </nz-tab>
  <nz-tab nzTitle="Atribuídas" [nzDisabled]="true">
    <!-- <app-assigned-notifications [listItems]="listAssigned"></app-assigned-notifications>     -->
  </nz-tab>
  <nz-tab nzTitle="Menções" [nzDisabled]="true">
    <!-- <app-mentions-notifications [listItems]="listContent"></app-mentions-notifications> -->
  </nz-tab>
</nz-tabset>
