import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import {
  DocumentItemOrAttachments,
  DocumentstListAttachments,
  HistoricDocuments,
} from 'src/app/shared/models/documents-list-item.model';
import { DocumentListService } from '../services/documents-list.service';
import { UtilService } from 'src/app/shared/util.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DeleteFileModalComponent } from '../../shared/delete-file-modal/delete-file-modal.component';
import { RestoreFileModalComponent } from '../../shared/restore-file-modal/restore-file-modal.component';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';

@Component({
  selector: 'app-historic-element',
  templateUrl: './historic-element.component.html',
  styleUrls: ['./historic-element.component.scss'],
})
export class HistoricElementComponent implements OnInit {
  @Input() document!: DocumentItemOrAttachments;
  @Input() enteredFolder!: number;
  selectHistory: HistoricDocuments[] = [];
  @Output() requestData = new EventEmitter<any>();
  @Output() requestItemChecked = new EventEmitter<any>();
  checked = false;
  @Output() refreshDisplayAfterDelete = new EventEmitter<any>()
  @Output() refreshDisplayAfterRestore = new EventEmitter<any>()

  documentsList: DocumentItemOrAttachments[] = [];

  listOfColumnFiles = [
    {
      title: 'Arquivos',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.name.localeCompare(b.name),
      priority: true,
    },
    {
      title: 'Versão',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.version.localeCompare(b.version),
      priority: 3,
    },
    {
      title: 'Comentários',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.comments.localeCompare(b.comments),
      priority: 2,
    },
    {
      title: 'Data/Hora da criação',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.date.localeCompare(b.date),
      priority: 1,
    },
    {
      title: 'Responsável',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.user.name.localeCompare(b.user.name),
      priority: 1,
    },
  ];
  fileRestore: boolean = false;

  constructor(
    private readonly util: UtilService,
    private service: DocumentListService,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fileRestore = this.util.getAuthorization("edit", "DOCUMENTS")
    this.getHistoric(this.document);
  }

  getHistoric(document: DocumentItemOrAttachments) {
    const entityId = document.entityId || 0;
    const entityName = document.entityName || '';
    const name = document.name || '';
    if (!document.version) return;
    this.service.getHistoric(entityId, entityName, name).subscribe((data) => {
      this.selectHistory = data;

      const highestVersion = Math.max(...this.selectHistory.map(obj => obj.version));
      const filteredArray = this.selectHistory.filter(obj => obj.version <= highestVersion);

      this.selectHistory = filteredArray;

    });
  }
  getIconFromFileName(filename: string) {
    if (filename !== undefined) {
      return this.util.getIconFromFileName(filename);
    }
    return null;
  }

  sendChecking() {
    this.requestData.emit(this.checked);
  }

  sendOnItemCheking(
    id: number,
    checked: boolean,
    data: DocumentItemOrAttachments | HistoricDocuments
  ) {

    this.requestItemChecked.emit({ id, checked, data });
  }

  public restoreFile(id) {
    let modal = this.modal.create<RestoreFileModalComponent>({
      nzTitle: 'Restaurar Arquivo',
      nzWidth: 600,
      nzContent: RestoreFileModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCentered: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        modal.destroy();
      },
    });

    const instance = modal.getContentComponent();
    instance.fileId = id;

    modal.afterClose.subscribe(() => {
      const entityId = this.document.entityId || 0;
      const entityName = this.document.entityName || '';
      const name = this.document.name || '';
      this.service.getHistoric(entityId, entityName, name).subscribe((data) => {
        this.selectHistory = data;
      });
      this.refreshDisplayAfterRestore.emit(this.enteredFolder);
      modal.destroy();
      this.ngOnInit();
    });
  }

  public deleteFile(id, name) {
    let modal = this.modal.create<DeleteFileModalComponent>({
      nzTitle: 'Excluir Arquivo',
      nzWidth: 600,
      nzContent: DeleteFileModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCentered: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        modal.destroy();
        this.ngOnInit();
      },
    });

    const instance = modal.getContentComponent();
    instance.fileId = id;
    instance.fileName = name;

    modal.afterClose.subscribe(() => {
      if (modal.componentInstance?.isDeleted) {
        this.refreshDisplayAfterDelete.emit(id);
      }
      modal.destroy();
      this.ngOnInit();
    });
  }

  public commentOnFile(id   ) {
    let modal = this.modal.create<CommentModalComponent>({
      nzTitle: 'Cadastrar Comentário',
      nzWidth: 600,
      nzContent: CommentModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCentered: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        modal.destroy();
        this.ngOnInit();
      },
    });

    const instance = modal.getContentComponent();
    instance.attachmentId = id;

    modal.afterClose.subscribe(() => {
      modal.destroy();
      this.ngOnInit();
    });
  }
}
