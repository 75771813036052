import { Store } from '@ngrx/store';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzModalService } from 'ng-zorro-antd/modal';

import { LicensingActivityEnum } from 'src/app/shared/enum/licensing-activity.enum';

import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';

// import { BREADCRUMB_ACTIONS } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';

import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';

import {
  LicenseActivitiesBody,
  LicenseActivitityUpdateBody,
} from 'src/app/modules/projects/projects-licensing/licensing.interface';

import { UtilService } from 'src/app/shared/util.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { TextShortnerHelper } from 'src/app/shared/components/text-shortner/text-shortner.helper';
import { CommunicationsListResponseDto } from 'src/app/shared/dto/communications-list-response.dto';
import { LicensingService } from 'src/app/modules/projects/projects-licensing/service/licensing.service';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';

@Component({
  selector: 'app-new-activity',
  templateUrl: './new-activity.component.html',
  styleUrls: ['./new-activity.component.scss'],
})
export class NewActivityComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<any> = new Subject();
  activity;
  user: any;
  formFields;
  params: any;
  project: any; //ProjectByIdResponseDto;
  isEIA = false;
  activitiesTypes;
  editMode = false;
  listOfUsers: any;
  userRoleList?: any;
  userComplexList?: any;
  disabledActions = true;
  projectIdParam: number;
  activityIdParam: number;
  activityIdParamForBreadcrumb: number;
  projectComplexList?: any;
  badgeColor: any = 'gray';
  activitySubIdParam: number; //Id da atvidade, usado para criar uma subatividade
  titleActivityEdit: string = '';
  projectLicensingIdParam: number;
  isUserPermission: boolean = true;
  enumStatusActivity = LicensingActivityEnum;
  listOfActivities: Array<{ id: string; description: string }> = [];
  customStyle = {
    background: 'none',
    'font-size': '12px',
  };
  communicationsList: CommunicationsListResponseDto[] = [];

  nzFilterOption = (): boolean => true;

  compareFn = (o1: any, o2: any) => (o1.id && o2.id ? o1.id === o2.id : o1 === o2);
  license: any;
  licensePreview: any;
  activityPrevious: any;
  retry = 0;
  loadingCommunications = true;
  cadActivityInsert: boolean = false;
  cadActivityEdit: boolean = false;
  cadActivityExclude: boolean = false;
  cadCommunications: boolean = false;
  projectId: number;
  projectIdForBreacrumb: number;
  savingActivity: boolean = false;
  processId: string;
  pageType: string;
  hasActivityCreated: boolean = false;
  activityIds: number[] = [];

  constructor(
    public readonly utilService: UtilService,
    private readonly _location: Location,
    private readonly _message: MessageService,
    private readonly _router: Router,
    private readonly _modalService: NzModalService,
    private _licensingService: LicensingService,
    private _projectsV2Service: ProjectsV2Service,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _datePipe: DatePipe,
    private _store: Store<BreadcrumbState>,
    public textShortnerHelper: TextShortnerHelper,
  ) {}

  ngOnInit() {
    this.getTitles();
    this.cadActivityInsert = this.utilService.getAuthorization(
      'insert',
      'CADACTIVITY'
    );
    this.cadActivityEdit = this.utilService.getAuthorization(
      'edit',
      'CADACTIVITY'
    );
    this.cadActivityExclude = this.utilService.getAuthorization(
      'exclude',
      'CADACTIVITY'
    );
    this.cadCommunications = this.utilService.getAuthorization(
      'insert',
      'CADCOMMUNICATIONS'
    );

    this.getLicensesByProjectId(
      Number(this._route?.snapshot.params['processId'])
    )

    this.processId = this._route.snapshot.url[1].path;
    this.pageType = this._route.snapshot.url[2].path;
    this._route.params.subscribe(() => {
      this.getRouteIds();
      this.setMode();
      this.initForm();
      this.loadUserSession();
      this.getComplex();

    });


  }

  setMode() {
    if (window.location.href.indexOf('edit') > -1) {
      this.editMode = true;
      this.disabledActions = false;
      if(this.activityIdParam) this.getActivityById();
    } else {
      this.editMode = false;
      if(this.pageType === RoutesEnum.SUB_ACTIVITIES && this.activityIdParam) {
        this.getActivityById()
      }
    }
  }

  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getLicensesByProjectId(projectId) {
    this._licensingService.getLicencing(projectId).subscribe({
      next: (licenses) => {
        this.license = licenses;
        this.project = licenses.project;
        this.licensePreview = licenses;
      },
      complete: () => {
        if(this.activityIdParam) this.getActivityById();

      },
      error: () => {
        console.error('Erro no Processo');
      },
    });
  }

  setHasActivity($event) {
    this.hasActivityCreated = $event;
  }

  // FINAL BREADCRUMB

  initForm() {
    this.formFields = {
      title: 0,
      description: '',
      responsible: '',
      deadline: '',
      conclusionDate: '',
      status: '',
    };
  }

  isUpdate(): void {
    if (this.activityIdParam) {
      this.getActivityById();
      this.getLicensesByProjectId(
        Number(this._route?.snapshot.params['processId'])
      );
    }
    this.isEiaProduct();
  }

  isDisableInput(): boolean {
    if(this._route.snapshot.url[2].path === RoutesEnum.SUB_ACTIVITIES) {
      return false
    }
    return !(!this.activityIdParam && this.isUserPermission);
  }

  setColorBadge(status: string): void {
    switch (status) {
      case this.enumStatusActivity.START:
      case this.enumStatusActivity.CANCELED:
        this.badgeColor = 'gray';
        break;
      case this.enumStatusActivity.HIRING:
      case 'MOB':
      case this.enumStatusActivity.ELABORATION:
      case this.enumStatusActivity.REVIEW:
        this.badgeColor = 'yellow';
        break;
      case this.enumStatusActivity.COMPLETED:
        this.badgeColor = 'green';
        break;
      default:
        this.badgeColor = 'default';
    }
  }

  loadUserSession(): void {
    const userRecuperado = sessionStorage.getItem('user');
    this.user = userRecuperado ? JSON.parse(userRecuperado) : null;
  }

  buildPayload(): LicenseActivitiesBody {
    return {
      projectLicensingId: this.projectLicensingIdParam,
      titleId: this.formFields.title,
      description: this.formFields.description,
      userResponsibleId: this.formFields.responsible,
      deadline:
        this._datePipe
          .transform(this.formFields.deadline, 'yyyy-MM-dd')
          ?.toString() + 'T00:00:00',
    };
  }

  buildCustomMessageDelete(): any {
    return {
      nzTitle: 'Deseja mesmo excluir esta atividade?',
      nzContent:
        'As informações contidas na atividade e subatividades relacionadas serão perdidas e não poderão ser recuperadas.',
      nzOkText: 'Excluir atividade',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }

  navigateBack() {
    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: `${this.projectIdParam}`
    });

    const routeEditActivity = buildRoute(RoutesEnum.EDIT_ACTIVITY, {
      projectId: `${this.projectIdParam}`,
      processId: `${this.projectLicensingIdParam}`
    });

    const routeEditprocess = buildRoute(RoutesEnum.EDIT_PROCESS_V2, {
      projectId: this.projectIdParam,
      processId: this.projectLicensingIdParam
    });

    const index = this.activityIds.indexOf(this.activityIdParam);

    if (index === 0) {
      this._router.navigate([`/${RoutesEnum.PROJECTS}${routeEditprocess}`]);
    }

    if (index > 0) {
      this.activityIds.splice(index, 1);
      this._router.navigate([`/${routeProject}/${routeEditActivity}/${this.activityIds.join('/')}`], { queryParams: { mode: 'edit' } });
      setTimeout(() => {
        window.location.reload();
      }, 1);
    }
  }

  navigateProcess() {
    const routeEditprocess = buildRoute(RoutesEnum.EDIT_PROCESS_V2, {
      projectId: `${this.projectIdParam}`,
      processId: `${this.projectLicensingIdParam}`
    });

    if (this.activitySubIdParam) {
      this._location.back();
    } else {
      this._router.navigate([`${RoutesEnum.PROJECTS}${routeEditprocess}`]);
    }
  }

  search(event: string): void {
    this.listOfActivities = this.activitiesTypes.filter((activity) =>
      activity.description.toLowerCase().includes(event.toLowerCase())
    );
  }

  onCancel(): void {
    this._modalService.confirm({
      ...(this._message.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this.navigateProcess();
      },
    });
  }

  onChangeStatusActivity(statusEnum: string, color: string): void {
    this.formFields.status = statusEnum;
    this.badgeColor = color;
    this.updateActivity();
  }

  validateUserPermission(): void {
    this.isUserPermission = true;
    return;
  }

  validateUserPermissionManager(): boolean {
    if (this.userRoleList && this.userRoleList.length > 0) {
      const role = this.userRoleList.filter(
        (userRole) => userRole.role.includes('GESTOR')
      );
      return role.length > 0;
    } else {
      return false;
    }
  }

  // REQUESTS
  getComplex(): void {
    /* GLOBAL USE - MOVE TO USER SERVICE */
    const userLogged = this._userService.getLoogedUser();
    const currentProject = this._projectsV2Service.getProjectByProjectId(
      this.projectIdParam
    );
    forkJoin([userLogged, currentProject])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([user, project]) => {
          this.userComplexList = user.complexes;
          this.userRoleList = user.roles;
          this.project = project;
          this.projectComplexList = project.complex;
          this.getResponsibleList();
          this.validateUserPermission();
          this.validateUserPermissionManager();
          this.setPagesBreadcrumb();
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
        },
      });
  }

  public getRouteIds() {
    const url = this._location.path();
    let atividadeSegment = '';
    //NEW_ACTIVITY_PARAM
    if(!(url.indexOf(RoutesEnum.NEW_ACTIVITY_PARAM) > -1)){
      if(url.indexOf(RoutesEnum.SUB_ACTIVITIES) > -1){
        atividadeSegment = url.split(`/${RoutesEnum.SUB_ACTIVITIES}/`)[1];
        atividadeSegment = atividadeSegment.split('/')[0];
      } else {
        atividadeSegment = url.split(`/${RoutesEnum.ACTIVITIES}/`)[1];
        atividadeSegment = atividadeSegment.split('?')[0];
      }
      if (atividadeSegment) {
        this.activityIds = atividadeSegment.split('/').map(id => +id);
        this.activityIdParam = this.activityIds[this.activityIds.length-1];
      }
    }



    this._route.parent?.params.pipe(takeUntil(this.destroy$)).subscribe({
      next: (params: Params) => {
        this.projectIdParam = +params['projectId'];
        this.projectLicensingIdParam = Number(
          this._route?.snapshot.params['processId']
        );

        this.activitySubIdParam = Number(
          this._route?.snapshot.params['activitySubId']
        );
        this.isUpdate();
      },
      error: (err) => {
        this._message.showErrorMessage(err.error.errors.details);
        this.navigateProcess();
      },
    });
  }

  getTitles() {
    if (this.isEIA) {
      this._licensingService.getTitles('EIA').subscribe({
        next: (data) => {
          this.activitiesTypes = data;
          this.listOfActivities = data;
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
    } else {
      this._licensingService.getTitles().subscribe({
        next: (data) => {
          this.activitiesTypes = data;
          this.listOfActivities = data;
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
    }
  }

  getResponsibleList() {
    this._userService
      .getUsersByComplex(this.projectComplexList.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.listOfUsers = response;
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
  }

  getActivityById(): void {
    if (this.pageType === RoutesEnum.SUB_ACTIVITIES && !this.activity) {
      this._licensingService
        .getActivityById(this.activityIdParam)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.activity = response;
            if(this.pageType === RoutesEnum.SUB_ACTIVITIES){
              this.formFields = {
                title: '',
                description: '',
                responsible: '',
                deadline: '',
                conclusionDate: '',
                status: '',
              };
            } else {
              this.formFields = {
                title: response.title.id,
                description: response.description,
                responsible: response.responsibleUser.id,
                deadline: response.deadline + 'T00:00:00',
                conclusionDate: response.conclusionDate,
                status: response.status,
              };
            }
            this.activityIdParam = response.activityParentId;
            this.communicationsList = response.communications;
            this.loadingCommunications = false;

            this.setColorBadge(response.status);
            this.titleActivityEdit = response.title.description;
            if (this.activity?.activityParentId) {
              this._licensingService
                .getActivityById(this.activity?.activityParentId)
                .subscribe({
                  next: (result) => {
                    this.activityPrevious = result;
                  },
                  complete: () => {
                  },
                });
            }
            this.isEiaProduct();
          },
          complete: () => {
          },
          error: (err) => {
            this._message.showErrorMessage(err.error.errors.details);
            this.navigateProcess();
          },
        });
    } else {
      this._licensingService
      .getActivityById(this.activityIdParam)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.activity = response;
          this.formFields = {
            title: response.title.id,
            description: response.description,
            responsible: response.responsibleUser.id,
            deadline: response.deadline + 'T00:00:00',
            conclusionDate: response.conclusionDate,
            status: response.status,
          };
          this.communicationsList = response.communications;
          this.loadingCommunications = false;

          if(this.pageType === RoutesEnum.SUB_ACTIVITIES){
            this.formFields = {
              title: '',
              description: '',
              responsible: '',
              deadline: '',
              conclusionDate: '',
              status: '',
            };
          }

          this.setColorBadge(response.status);
          this.titleActivityEdit = response.title.description;
          if (this.activity?.activityParentId) {
            this._licensingService
              .getActivityById(this.activity?.activityParentId)
              .subscribe({
                next: (result) => {
                  this.activityPrevious = result;
                },
                complete: () => {
                  this.isDisableInput()
                }
              });
          }
          this.isEiaProduct();
        },
        complete: () => {
          this.isDisableInput()
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
    }


    if (this.pageType === RoutesEnum.SUB_ACTIVITIES || this.pageType === 'new-activity') {
      this.isDisableInput()
    }
  }

  saveActivity() {
    this.savingActivity = true;
    if (
      !this.formFields.title ||
      !this.formFields.description ||
      !this.formFields.responsible ||
      !this.formFields.deadline
    ) {
      this._message.showErrorMessage(
        'Todos os campos obrigatórios devem ser preenchidos!'
      );
      this.savingActivity = false;
      return;
    }

    const payload = this.buildPayload();
    let feedbackSucess = 'Nova atividade cadastrada';

    if (this.activitySubIdParam) {
      payload.activityParentId = this.activitySubIdParam;
      feedbackSucess = 'Nova subatividade cadastrada';
    }

    this._licensingService.createNewActivity(payload).subscribe({
      next: () => {
        this._message.showSucessMessage(feedbackSucess);
        this.savingActivity = false;
        this.navigateProcess();
      },
      error: (err) => {
        if (err.error && err.error.message) {
          this._message.showErrorMessage(err.error.message);
        } else if (err.error && err.error.errors.details) {
          this._message.showErrorMessage(err.error.errors.details);
        } else if (err.message) {
          this._message.showErrorMessage(err.message);
        } else {
          this._message.showErrorMessage('Erro ao criar atividade');
        }
        this.savingActivity = false;
      },
    });
  }

  deleteActivity() {
    if (this.activityIdParam || this.activitySubIdParam) {
      let id = this.activityIdParam
        ? this.activityIdParam
        : this.activitySubIdParam;

      this._modalService.confirm({
        ...this.buildCustomMessageDelete(),
        nzOnOk: () => {
          this._licensingService.deleteActivity(id).subscribe({
            next: () => {
              this._message.showSucessMessage('Atividade Removida');
              this.navigateProcess();
            },
            error: () => {},
          });
        },
      });

    }
  }

  isEiaProduct() {
    if (
      this.activity &&
      this.activity.title.description === 'Estudo de Impacto Ambiental'
    ) {
      return (this.isEIA = true);
    } else if (this._route?.snapshot.params['product'] === 'EIA') {
      return (this.isEIA = true);
    } else {
      return (this.isEIA = false);
    }
  }

  toNewSubActivity() {
    if (this.activityIdParam) {
      this._router.navigate([
        `/projects/${this.projectIdParam}/process/${this.processId}/new-sub-activity/${this.activityIdParam}/new`
      ], { queryParams: { activityId: this.activityIdParam } });
    }
  }

  buildPayloadUpdate(): any {
    return {
      userResponsibleId: this.formFields.responsible,
      deadline: this._datePipe
        .transform(this.formFields.deadline, 'yyyy-MM-dd')
        ?.toString() as string,
      title: this.formFields.description,
      description: this.formFields.description,
      status: this.formFields.status,
      environmentalStudy: false
    };
  }

  updateActivity(): void {
    if (!this.editMode) return;

    this._licensingService
      .updateActivityById(this.activityIdParam, this.buildPayloadUpdate())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.formFields.conclusionDate = data.conclusionDate;
          this._message.showSucessMessage('Atividade Atualizada');
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
        },
      });
  }

  newCommunication(type: string) {
    let navigationParams = {
      queryParams: {
        projectId: this.projectIdParam,
        processId: this.license.id,
        type: type,
        activityId: this.activity.id,
      },
    };

    this._router.navigate([`/${RoutesEnum.COMMUNICATION}/new`], navigationParams);
  }

  setPagesBreadcrumb(activityId?: number): void {
    const pages: Page[] = [];

    // Função recursiva para buscar a atividade e adicionar ao array
    const fetchActivity = (activityIdParam: number) => {
      this._licensingService
        .getActivityById(activityIdParam)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {

            const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
              projectId: this.projectIdParam,
            });

            const routeProcess = buildRoute(RoutesEnum.EDIT_ACTIVITY, {
              processId: response.projectLicenseId,
            });

            const url = `${routeProject}/${routeProcess}/${response.id}?mode=edit`.replace('%3F', '?').replace('%3D', '=');

            pages.push({
              id: response.id,
              name: response.title.description || '',
              url: url,
            });

            // Se houver um parentId, chama a função novamente de forma recursiva
            if (response.activityParentId) {
              fetchActivity(response.activityParentId);
            } else {

              // Ordena as páginas pelo ID antes de construir o breadcrumb
              pages.sort((a, b) => {
                // Converte `id` para número se estiver definido
                const idA = typeof a.id === 'string' ? parseFloat(a.id) : a.id;
                const idB = typeof b.id === 'string' ? parseFloat(b.id) : b.id;

                // Assegura que idA e idB são números antes da comparação
                return (idA ?? Number.POSITIVE_INFINITY) - (idB ?? Number.POSITIVE_INFINITY);
              });

              updateUrls(pages, `${routeProject}/${routeProcess}`);

              // Chama o método para construir o breadcrumb
              this.buildBreadcrumb(pages, response.projectLicenseId);
            }
          },
          error: (err) => {
            this._message.showErrorMessage(err.error.errors.details);
          },
        });
    };

    // Função para atualizar as URLs
    function updateUrls(items: Page[], url): void {
      items.forEach((item, index) => {
        // Obter todos os IDs anteriores
        const previousIds = items.slice(0, index).map(i => i.id).join('/');

        // Atualizar a URL
        const urlParts = item.url.split('?mode=edit');

        item.url = `${url}/${previousIds}${previousIds ? '/' : ''}${item.id}?mode=edit`;
      });
    }

    // Inicia a recursão
    fetchActivity(activityId || this.activityIdParam);
  }


  buildBreadcrumb(pages: Page[], projectLicenseId: string | number): void {
    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: this.projectIdParam,
    });

    const routeProcess = buildRoute(RoutesEnum.PROCESS_BY_ID, {
      processId: projectLicenseId,
    });

    let pagesArray: Page[] = [
      {
        name: "Projetos",
        url: `/${RoutesEnum.PROJECTS}`,
      },
      {
        name: this.project.name,
        url: `${routeProject}`,
      },
      {
        name: this.license.title,
        url: `${routeProject}/${routeProcess}`
      },
    ];

    pagesArray = [...pagesArray, ...pages];

    if(this.pageType === RoutesEnum.SUB_ACTIVITIES){
      pagesArray.push({
        name: 'Nova subatividade',
        url: ''
      });
    }

    this._store.dispatch(setPages({ pages: pagesArray }));
  }
}
