import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";

const DELETE_ENTITY_ENDPOINT = '/delete-entity'

@Injectable({
  providedIn: 'root'
})
export class DeletionService {
  constructor(private readonly http: HttpClient) { }

  deleteByEntityNameAndId(entityName: string, entityId: string): Observable<any> {
    return this.http.delete<any>(`${DELETE_ENTITY_ENDPOINT}/${entityName}/${entityId}`);
  }
}
