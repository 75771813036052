import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ImenuConfig {
  title: string;
  eventType: string;
  icon: string;
  disabled: boolean;
}

@Component({
  selector: 'app-button-options-dots',
  templateUrl: './button-options-dots.component.html',
  styleUrl: './button-options-dots.component.scss'
})
export class ButtonOptionsDotsComponent {

  @Input() title: string = 'Ações';
  @Input() popoverPlacement: 'bottomRight' | 'bottomLeft';
  @Input() styleCss: string = '';
  @Input() menuConfig: ImenuConfig[] = [];
  
  @Output() clickActionMenu: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onClickActionMenu(eventType: string, disabled: boolean): void {
    if (disabled) return;
    this.clickActionMenu.emit(eventType);
  }
  
  getIconPath(iconName: string): string {
    return `./assets/icons/${iconName}.svg`;
  }

}
