import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessesListResponseDto } from 'src/app/shared/dto/process-list-response.dto';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';

@Component({
  selector: 'app-linked-processes-container',
  templateUrl: './linked-processes-container.component.html',
  styleUrls: ['./linked-processes-container.component.scss']
})
export class LinkedProcessesContainerComponent implements OnInit{
  @Input() processName: string;
  @Input() projectId:number;
  @Input() linksToEdit;
  @Output() updateLinks: EventEmitter<any[]> = new EventEmitter<any[]>();
  showLinks = false;
  availbleLinks:ProcessesListResponseDto[];
  listProcesses: any;

  constructor(private readonly projectsService:ProjectsV2Service) { }

  ngOnInit() {
    this.getAvailableProcess();
  }

  toggleLinks() {
    if(this.processName.length <= 1) {
      this.showLinks = false;
      return;
    }
    this.showLinks = !this.showLinks;
  }

  getAvailableProcess(){
    this.projectsService.getProcessessList(this.projectId).subscribe({
      next: (data) => {
        this.availbleLinks = data.content;
      }
    })
  }

  listUpdate(list){
    this.listProcesses = list;
    this.updateLinks.emit(list);
  }

}
