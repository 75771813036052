import { Video } from "src/app/shared/models/video.model";

export const VIDEOS: Video[] = [
  {
    key: "processes",
    title: "Quando e como criar um processo?",
    description: "Inicie a elaboração dos processos de licenciamento logo após a publicação da RIPLA para planejar e acompanhar todas as atividades necessárias para a sua realização.",
    thumbnail: "/assets/video/sample.png",
    url: "https://stkeycloak.blob.core.windows.net/videos/CadastroRipla.mp4?sp=r&st=2024-07-10T18:37:45Z&se=2024-12-21T02:37:45Z&spr=https&sv=2022-11-02&sr=b&sig=kzCzOV0vkrDrGzQ39cVpdtdlOg0PGe9g8d17UC9rWgE%3D"
  },
];
