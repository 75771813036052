import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollContainer', { static: false, read: ElementRef }) scrollContainer!: ElementRef;

  wholeList:any[] = [];
  listContent:any = [];
  listAssigned;
  allNotifications: any[] = [];

  antDrawerBodyElementRef: any;

  currentPage = 0;
  totalPages = 1;
  isLoading = false;

  constructor(
    private notificationService: NotificationsService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
   this.getAllNotifications();
  this.getAssignedNotifications(); 

  }

  ngOnDestroy(): void {
    this.antDrawerBodyElementRef = null;
  }

  ngAfterViewInit() {
    this.addScrollListener();
  }
  
  addScrollListener() {
    const scrollableDiv = this.scrollContainer.nativeElement;
  
    scrollableDiv.addEventListener('scroll', () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 1;
      if (isBottom && !this.isLoading) {
        this.getAllNotifications();
      }
    });
  }

  getAllNotifications() {
    if (this.currentPage >= this.totalPages || this.isLoading) return;
  
    this.isLoading = true;
    this.cdr.detectChanges();
    this.notificationService.getAllNotifications(this.currentPage).subscribe({
      next: (data: any) => {
        const newNotifications = Array.isArray(data.content) ? data.content : [];
        this.totalPages = data.totalPages;
        this.wholeList = [...this.wholeList, ...newNotifications];
        this.listContent = [...this.listContent, ...newNotifications];
        this.allNotifications = [...this.allNotifications, ...newNotifications];  
        this.cdr.detectChanges();
        this.currentPage++;
      },
      complete: () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    });
  }

  listFilter(event){

    if (event.length === 0) {
      this.listContent = this.allNotifications;
    }else{
      this.listContent = this.allNotifications.filter(element => element.projectName === event[0].projectName);
    }
  }

  getAssignedNotifications(){
    this.listAssigned = this.listContent.filter(item => item.notificationRecipients[0].main === true);
  }
  
}
