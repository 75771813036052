import {ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {NewProjectLicensingForm} from "./new-project-licensing-form/new-project-licensing-form.component";
import {LicensingService} from "../service/licensing.service";
import {Location} from '@angular/common';
import {MessageService} from "src/app/shared/common/services/message.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "src/app/shared/services/user.service";
import { buildRoute, RoutesEnum } from "src/app/shared/enum/routes.enum";

@Component({
  selector: 'app-new-project-licensing',
  templateUrl: './new-project-licensing.component.html',
  styleUrls: ['./new-project-licensing.component.scss']
})
export class NewLicensingProcessComponent implements OnInit {
  @ViewChild(NewProjectLicensingForm) projectLicensingForm: NewProjectLicensingForm;

  editMode = false;
  pageTitle: string = "";
  titleComplement: string = "";
  process: any = null
  payload: any;
  linkedProcesses: any[] = [];
  projectId: number;
  popOverNotification: boolean = false;
  profile;
  listOfActivitys: any[] = [];
  hasActivity: boolean = false;

  constructor(
    private readonly message: MessageService,
    private cdRef: ChangeDetectorRef,
    private licensingService: LicensingService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.getProject();
    this.profileConfigs();
  }

  public changeTitle = (newTitle: string) => {
    this.pageTitle = newTitle;

    this.cdRef.detectChanges();
  }

  formatTitleName() {
    const words = this.pageTitle.split(' ');
    if (words.length > 6) {
      return `${words.slice(0, 4).join(' ')} ...`;
    }
    return this.pageTitle;
  }

  setHasActivities(event) {
    this.hasActivity = event;
  }

  public getProject() {
    this.route.parent?.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
    });
  }

  public checkIfNeedToRemoveSeparator(titleComplementInput) {
    if (titleComplementInput.target.value !== "" && !titleComplementInput.target.value.includes(' - ')) {
      titleComplementInput.target.value = ' - ' + titleComplementInput.target.value;
    }
  }

  public saveProcess() {
    if (!this.validateForm()) {
      return;
    }
    if(this.pageTitle === '' || (this.pageTitle += this.titleComplement) === '' ){
      this.message.showErrorMessage("Por gentileza inserir descrição do processo de licenciamento");
      return;
    }
    this.payload = this.projectLicensingForm.projectLicensingCreateForm;
    this.payload.projectId = this.projectId;
    this.payload.title = this.pageTitle += this.titleComplement;
    this.payload.linkedProcesses = this.linkedProcesses.map(p => p.id);

    this.licensingService.createProcessLicensing(this.payload).subscribe({
      next: (processCreated) => {
        this.process = processCreated;
        this.updateFormView();
        this.message.showSucessMessage('Processo criado com sucesso!');
        this.router.navigate([`/projects/${this.projectId}/edit-process/${this.process.id}`])
      },
      error: () => {
        this.message.showErrorMessage('Erro ao criar processo!');
      }
    })
  }

  validateForm() {
    if (this.projectLicensingForm.projectLicensingCreateForm.competentOrganId > 0 && this.projectLicensingForm.projectLicensingCreateForm.licenseTypeId > 0) {
      return true;
    } else {
      return false;
    }
  }

  linkedProcessCount(list) {
    this.linkedProcesses = list;
  }

  public goBack() {
    this.location.back();
  }

  profileConfigs() {
    this.profile = this.userService.getSessionConfigRoles[0].description;
  }

  onActionSelected(actionMethod: Function): void {
    actionMethod();
  }

  deleteProcess() {
    this.licensingService.createProcessLicensing(this.payload).subscribe({
      next: (data) => {
        this.message.showSucessMessage('Processo deletado com sucesso!');
        const routeprocesses = buildRoute(RoutesEnum.PROCESSES, {projectId: this.projectId});
        this.router.navigate([`/${RoutesEnum.PROJECTS}${routeprocesses}`]);
      }
    });
  }

  updateFormView() {
    const blankKeys: any[] = [];
    const processData = this.process;

    this.process = processData;
  }

}
