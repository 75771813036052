<div class="new-project-licensing-wrapper">
  <header class="subheader">
    <section class="title-container">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="goBack()">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69708 2.96569C10.0095 2.65327 10.0095 2.14674 9.69708 1.83432C9.38466 1.5219 8.87813 1.5219 8.56571 1.83432L2.40002 8.00001L8.56571 14.1657C8.87813 14.4781 9.38466 14.4781 9.69708 14.1657C10.0095 13.8533 10.0095 13.3467 9.69708 13.0343L5.46281 8.80005H13.6002C14.042 8.80005 14.4002 8.44188 14.4002 8.00005C14.4002 7.55822 14.042 7.20005 13.6002 7.20005H5.46272L9.69708 2.96569Z" fill="#969696"/>
      </svg>

      <h1 class="subheader__title" *ngIf="!pageTitle">
        {{ 'licensing-process.new-process-title' | translate }}
      </h1>
      <div *ngIf="pageTitle" class="customizable-title-cont">
        <h1 class="subheader__title" [nzTooltipTitle]="pageTitle"
        nzTooltipPlacement="bottom"
        nz-tooltip>{{ formatTitleName() }}</h1> &nbsp;
        <input nz-input
          *ngIf="!editMode"
          class="process-title-input"
          id="processTitle"
          nzBorderless placeholder="- Clique aqui para inserir a descrição"
          (blur)="checkIfNeedToRemoveSeparator($event)"
        >
      </div>
    </section>

    <section class="action-buttons" *ngIf="!editMode">
      <button class="secondary-button">
        <span>{{ 'commons.cancel' | translate }}</span>
      </button>

      <button class="primary-button" (click)="saveProcess()">
        <span>{{ 'licensing-process.save' | translate }}</span>
      </button>
    </section>
  </header>

  <section class="process-section">
    <app-new-project-licensing-form *ngIf="!editMode"
      class="licensing-form"
      [changePageTitle]="changeTitle"
      [process]="process"
      [projectId]="projectId"
    ></app-new-project-licensing-form>

    <span class="stp-10"></span>
    <app-linked-processes-container [processName]="pageTitle" [processName]="pageTitle" [linksToEdit]="linkedProcesses" (updateLinks)="linkedProcessCount($event)" [projectId]="projectId"></app-linked-processes-container>
  </section>

  <div class="row-multiple-columns mt-10">
    <div class="left-column">
      <app-activities-container
        [projectId]="projectId"
        [disabledBlock]="true"
        (hasActivity)="setHasActivities($event)"
      >
      </app-activities-container>
    </div>
    <div class="right-column vert-column">
      <app-communications-container [enableCreation]="hasActivity" [params]="{projectId: projectId}" [disabledBlock]="true"></app-communications-container>
    </div>
  </div>
</div>
