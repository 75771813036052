import keycloakPreProdConfig from './keycloak-pre-prod.config'

export const environment = {
  production: false,
  keycloak: keycloakPreProdConfig,
  apiUrl: 'https://api.preprod.smartlicensingweb.com',
  workspace: '0c7e4d17-cb83-41cc-ae4b-2dd0711920e3',
  dashStudy: '35d9069d-cd7e-463b-8893-88433efef1d6',
  dashSuppliers: 'eae41a8c-337a-4ad5-b4c2-c69c9b3fef1c',
  dashLicensing: '23145c50-2aa7-4335-a2c4-c3e998c2b5fb',
  dashManager: '62f57231-9326-4478-8a6f-e7224c677a7e'
}

