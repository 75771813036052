import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-delete-confimation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss']
})
export class DeleteConfirmationModalComponent {
  @Input() titleText: string;
  @Input() subtitleText?: string;
  @Input() descriptionText?: string;
  @Input() cancelText?: string = '';
  @Input() confirmText?: string = '';

  @Output() confirmButton: EventEmitter<any> = new EventEmitter();

  onCancel(): void {
    this.confirmButton.emit(false);
  }

  onConfirmDeletion(): void {
    this.confirmButton.emit(true);
  }

}
