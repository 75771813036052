import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent {
  @Input() progressionType: string;
  @Input() progressValue?: number;
  BAR_PROGRESSION_TYPE = {
    notStarted: "not_started",
    inProgress: "in_progress",
    finished: "finished"
  }

  constructor() {}
}