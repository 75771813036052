<div class="loading-bar-container">
  <div class="loading-bar">
    <div *ngIf="progressValue && progressionType == BAR_PROGRESSION_TYPE.inProgress" 
      class="progression-value" [style.width.%]="progressValue">
    </div>
    <div
      [ngClass]="{
        'progress-bar-not-started': progressionType == BAR_PROGRESSION_TYPE.notStarted,
        'progress-bar-in-progress': progressionType == BAR_PROGRESSION_TYPE.inProgress,
        'progress-bar-finished': progressionType == BAR_PROGRESSION_TYPE.finished
      }"
    >
    </div>
  </div>
</div>