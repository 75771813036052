 /**
  * @param START A Iniciar
  * @param HIRING Em Contratação
  * @param MOBILIZATION Em Mobilização
  * @param ELABORATION Em Elaboração
  * @param REVIEW Em Revisão
  * @param COMPLETED Concluído
  * @param CANCELED Cancelado
  */
export enum LicensingActivityEnum {
    START = 'INI', 
    HIRING = 'COT',
    MOBILIZATION = 'MOB',
    ELABORATION = 'ELA',
    REVIEW = 'REV',
    COMPLETED = 'CON',
    CANCELED = 'CAN',
    CURRENT = 'CUR',
    EMITED = 'PEM'
  }
  