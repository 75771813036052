import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from "../../../../core/auth/service/auth.service";
import {models} from "powerbi-client";
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'app-dashboard-fornecedores',
  templateUrl: './dashboard-fornecedores.component.html',
  styleUrls: ['./dashboard-fornecedores.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardFornecedoresComponent implements OnInit {

  workspace: string = environment.workspace
  dashSupplier: string = environment.dashSuppliers
  embedConfig: any;
  constructor(private readonly authService: AuthService) {}


  ngOnInit(): void {

    this.authService.GetServicePrincipalAccessToken(this.dashSupplier,this.workspace).subscribe({
      next: data => {
        if(data){
          this.embedConfig = {
            type: "report",
            id: this.dashSupplier,
            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=eae41a8c-337a-4ad5-b4c2-c69c9b3fef1c&groupId=5e45386e-6583-49fc-9989-a6577193f157",
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              layoutType: models.LayoutType.Master,
              zoomLevel: 0.75,
              customLayout: {
                pageSize: { type: models.PageSizeType.Cortana},
                displayOption: models.DisplayOption.FitToWidth,
                reportAlignment: models.ReportAlignment.Center
              },
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            }
          }
        }
      }
    })

  }

}
