import {Injectable} from '@angular/core';
import {TASK_CREATE, TASK_LIST} from '../../projects/constants/apiEndpointsConstants';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {TaskPagedResponse} from "../interface/task-paged-response";

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  showErrorMessage(arg0: string) {
    throw new Error(arg0);
  }

  constructor(private readonly http: HttpClient) {
  }

  priorityList = [
    {
      name: "Prioritário",
      value: '1'
    },
    {
      name: "Não Prioritário",
      value: '0',
    },
  ]

  updateTaskPriority(params): Observable<Task> {
    return this.http.patch<any>(`${TASK_LIST}/${params.id}`, params);
  }

  updateTaskStatus(params): Observable<Task> {
    return this.http.patch<any>(`${TASK_LIST}/${params.id}`, params);
  }

  updateTaskDeadline(params): Observable<Task> {
    return this.http.patch<any>(`${TASK_LIST}/${params.id}`, params);
  }

  createTask(payload): Observable<any> {
    return this.http.post(`${TASK_CREATE}`, payload)
  }

  getTasksList(filters?: any): Observable<TaskPagedResponse> {
    let url = `${TASK_LIST}?size=100`;
    url = this.buildFilterUrl(filters, url);
    return this.http.get<TaskPagedResponse>(url);
  }

  private buildFilterUrl(filters: any, url: string) {
    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value) {
          url += `&${key}=${value}`;
        }
      }
    }
    return url;
  }
}
