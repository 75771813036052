<section class="newActivity__section app-new-activity">
  <header class="newActivity__header">
    <div class="newActivity__header__nav">
      <div class="newActivity__header__nav__item">
        <button id="back-button" class="button" (click)="navigateBack()">
          <img
            src="assets/icons/icon_arrow-back.svg"
            alt="Voltar"
            class="back"
          />
        </button>
        <div class="newActivity__header__nav__item">
          <h1
            class="newActivity__header__nav__item__title"
            *ngIf="!activitySubIdParam"
          >
            {{
              activityIdParam
                ? titleActivityEdit
                : ("activities.new-activity" | translate)
            }}
          </h1>
          <h1
            class="newActivity__header__nav__item__title"
            *ngIf="activitySubIdParam"
          >
            {{ "activities.new-sub-activity" | translate }}
          </h1>
        </div>
      </div>

      <div class="newActivity__header__nav__action">
        <button
          class="btn-sl btn-text-green"
          *ngIf="!editMode"
          (click)="onCancel()"
        >
          {{ "commons.cancel" | translate }}
        </button>
        <button
          class="btn-sl btn-sl-green"
          *ngIf="!editMode"
          [disabled]="savingActivity"
          (click)="saveActivity()"
        >
          {{ "commons.save" | translate }}
        </button>
        <button
          *ngIf="editMode"
          nz-popover
          nzPopoverPlacement="bottomLeft"
          nzPopoverTrigger="click"
          [nzPopoverContent]="contentTemplateMenu"
          class="btn__primary btn__primary__green"
        >
          <span class="btn__primary__span">
            {{ "projects.button-actions" | translate }}
          </span>
          <div class="btn__primary__divider"></div>
          <span nz-icon>
            <img
              src="assets/icons/icon_arrow_down_small.svg"
              alt="Expandir"
              class="btn__primary__ico"
            />
          </span>
        </button>
      </div>
    </div>
    <span *ngIf="editMode" class="newActivity__header__nav__subtitle">
      ID {{ activityIdParam.toString().padStart(10, "0") }}
    </span>
  </header>
  <ng-template #contentTemplateMenu>
    <div class="newActivity__contentTemplate" (click)="toNewSubActivity()">
      <span>
        <!-- Adicionar subatividade -->
        {{ "activities.add-activity" | translate }}
      </span>
    </div>
    <div
      *ngIf="cadCommunications"
      class="newActivity__contentTemplate"
      (click)="newCommunication('REC')"
    >
      <span>
        <!-- Adicionar comunicação recebida -->
        {{ "activities.add-communication-received" | translate }}
      </span>
    </div>
    <div
      *ngIf="cadCommunications"
      class="newActivity__contentTemplate"
      (click)="newCommunication('ENV')"
    >
      <span>
        <!-- Adicionar comunicação enviada -->
        {{ "activities.add-communication-sent" | translate }}
      </span>
    </div>
    <div
      *ngIf="cadActivityExclude"
      class="newActivity__contentTemplate"
      (click)="deleteActivity()"
    >
      <span>
        <!-- Excluir Atividade -->
        {{ "activities.delete-activity" | translate }}
      </span>
    </div>
  </ng-template>

  <div class="newActivity__container">
    <div class="newActivity__container__card">
      <h2 class="newActivity__container__card__title">
        {{ "activities.basic-information" | translate }}
      </h2>
      <nz-table
        nzSize="small"
        nzTemplateMode
        nzTableLayout="fixed"
        class="newActivity__table"
        tableBordered
        [nzNoResult]
      >
        <thead>
          <tr id="newActivityTable">
            <th id="title" nzWidth="15%">
              <!-- Título -->
              <span class="mandatory-indicator">*</span>
              {{ "commons.title" | translate }}
            </th>
            <th id="description" nzWidth="26%">
              <!-- Descrição -->
              <span class="mandatory-indicator">*</span>
              {{ "commons.description" | translate }}
            </th>
            <th id="responsible" nzWidth="10%">
              <!-- Responsável -->
              <span class="mandatory-indicator">*</span>
              {{ "activities.responsible-for-activity" | translate }}
            </th>
            <th id="deadline" nzWidth="10%">
              <!-- Prazo de conclusão -->
              <span class="mandatory-indicator">*</span>
              {{ "commons.conclusion-deadline" | translate }}
            </th>
            <th id="completed" nzWidth="8%">
              <!-- Completada em -->
              {{ "commons.completed-on" | translate }}
            </th>
            <th id="status" nzWidth="8%">
              <!-- Status -->
              {{ "commons.status" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- ACTIVITIES -->
            <td>
              <nz-select
                nzShowSearch
                nzServerSearch
                [nzDisabled]="isDisableInput()"
                class="titleActivity"
                nzPlaceHolder="{{ 'commons.inform-placeholder' | translate }}"
                [(ngModel)]="formFields.title"
                [compareWith]="compareFn"
                [nzBorderless]="true"
                [nzShowArrow]="false"
                [nzFilterOption]="nzFilterOption"
                (nzOnSearch)="search($event)"
                (blur)="updateActivity()"
              >
                <nz-option
                  *ngFor="let o of listOfActivities"
                  [nzLabel]="o.description"
                  [nzValue]="o.id"
                >
                </nz-option>
              </nz-select>
            </td>
            <!-- DESCRIPTION -->
            <td>
              <input
                nz-input
                placeholder="{{ 'commons.inform-placeholder' | translate }}"
                [(ngModel)]="formFields.description"
                [disabled]="!this.cadActivityInsert"
                [nzBorderless]="true"
                (blur)="updateActivity()"
                [nzTooltipColor]="'#fff'"
                [nzTooltipOverlayStyle]="customStyle"
                [nzTooltipTitle]="descriptionToolTipTemplate"
                nzTooltipPlacement="bottom"
                nz-tooltip
                [nzTooltipArrowPointAtCenter]="true"
                [nzTooltipOverlayClassName]="'activityDescriptionToolTip'"
                type="text"
              />
              <ng-template #descriptionToolTipTemplate>
                <span class="tooltipColor">{{ formFields.description }}</span>
              </ng-template>
            </td>
            <!-- RESPONSIBLE -->
            <td>
              <nz-select
                nzPlaceHolder="{{ 'commons.inform-placeholder' | translate }}"
                [(ngModel)]="formFields.responsible"
                [nzBorderless]="true"
                [nzShowArrow]="false"
                [nzDisabled]="!this.cadActivityInsert"
                (ngModelChange)="updateActivity()"
              >
                <nz-option
                  *ngFor="let option of listOfUsers"
                  [nzValue]="option.id"
                  [nzLabel]="textShortnerHelper.textShortner(option.name, 15)"
                >
                </nz-option>
              </nz-select>
            </td>
            <!-- DEADLINE DATE -->
            <td>
              <nz-date-picker
                nzPlaceHolder="{{ 'commons.inform-placeholder' | translate }}"
                nzFormat="dd/MM/YYYY"
                nzSize="small"
                nzBorderless
                [(ngModel)]="formFields.deadline"
                [nzSuffixIcon]="''"
                [nzDisabled]="!this.cadActivityInsert"
                (ngModelChange)="updateActivity()"
                [nzDisabledDate]="utilService.blockPreviousDates"
              >
              </nz-date-picker>
            </td>
            <!-- CONCLUSION DATE -->
            <td>
              <nz-date-picker
                nzPlaceHolder="-"
                [nzDisabled]="true"
                nzFormat="dd/MM/YYYY"
                [(ngModel)]="formFields.conclusionDate"
                [nzSuffixIcon]="''"
                nzSize="small"
                nzBorderless
                [nzDisabledDate]="utilService.blockPreviousDates"
              >
              </nz-date-picker>
            </td>

            <!-- STATUS -->
            <td>
              <app-badge
                *ngIf="!activityIdParam"
                badgeText="{{ enumStatusActivity.START }}"
              >
              </app-badge>

              <app-badge
                *ngIf="activityIdParam"
                badgeText="{{
                  ('activities.status.' + (formFields.status || 'INI')) | translate
                }}"
                [badgeColor]="badgeColor"
                [nzPopoverContent]="activity?.status === 'CON' ? undefined : contentTemplate"
                nzPopoverPlacement="bottom"
                nz-popover
                [nzPopoverTrigger]="activity?.status === 'CON' ? undefined : 'click'"
                [class]="activity?.status === 'CON' ? '' : 'cursor-pointer'"
              >
              </app-badge>

              <ng-template #contentTemplate>
                <div>
                  <div class="btnPopover" *ngIf="this.cadActivityInsert">
                    <button
                      class="btnGrayStatusActivity"
                      (click)="
                        onChangeStatusActivity(enumStatusActivity.START, 'gray')
                      "
                      nz-button
                    >
                      A iniciar
                    </button>
                    <button
                      class="btnBlueStatusActivity"
                      (click)="
                        onChangeStatusActivity(
                          enumStatusActivity.HIRING,
                          'blue'
                        )
                      "
                      nz-button
                    >
                      Em contratação
                    </button>
                    <button
                      class="btnBlueStatusActivity"
                      (click)="
                        onChangeStatusActivity(
                          enumStatusActivity.MOBILIZATION,
                          'blue'
                        )
                      "
                      nz-button
                    >
                      Em mobilização
                    </button>
                    <button
                      class="btnBlueStatusActivity"
                      (click)="
                        onChangeStatusActivity(
                          enumStatusActivity.ELABORATION,
                          'blue'
                        )
                      "
                      nz-button
                    >
                      Em elaboração
                    </button>
                    <button
                      class="btnYellowStatusActivity"
                      (click)="
                        onChangeStatusActivity(
                          enumStatusActivity.REVIEW,
                          'yellow'
                        )
                      "
                      nz-button
                    >
                      Em revisão
                    </button>
                    <button
                      class="btnGreeStatusActivity"
                      (click)="
                        onChangeStatusActivity(
                          enumStatusActivity.COMPLETED,
                          'green'
                        )
                      "
                      nz-button
                    >
                      Concluído
                    </button>
                    <button
                      class="btnGrayStatusActivity"
                      (click)="
                        onChangeStatusActivity(
                          enumStatusActivity.CANCELED,
                          'gray'
                        )
                      "
                      nz-button
                    >
                      Cancelado
                    </button>
                  </div>
                </div>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>

    <div class="newActivity__container__components">
      <div class="newActivity__container__components__left">
        <div class="newActivity__container__components__left__column">
          <app-activities-container
            [id]="projectLicensingIdParam"
            [projectId]="projectIdParam"
            [parentId]="activityIdParam"
            [activitySubId]="activitySubIdParam"
            [isEIA]="isEIA"
            [disabledBlock]="disabledActions"
            (hasActivity)="setHasActivity($event)"
          >
          </app-activities-container>
        </div>
      </div>

      <div class="newActivity__container__components__right">
        <div class="newActivity__container__components__right__column__top">
          <div class="newActivity__attachments__card">
            <app-upload-files-horz
              [attachmentsId]="activityIdParam"
              entityName="PROCESS_ACTIVITY"
              [disabledBlock]="disabledActions"
            ></app-upload-files-horz>
          </div>
        </div>

        <div class="newActivity__container__components__right__column__bottom">
          <div class="newActivity__communications__card">
            <app-communications-container
              [communicationsList]="communicationsList"
              [enableCreation]="hasActivityCreated"
              [params]="params"
              [disabledBlock]="disabledActions"
              [loadingCommunications]="loadingCommunications"
            >
            </app-communications-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
